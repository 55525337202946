/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Para ", React.createElement(_components.strong, null, "luchar contra los cúmulos de grasa"), " que no conseguimos eliminar con dieta y ejercicio contamos con la ", React.createElement(_components.a, {
    href: "https://www.clinicaplanas.com/cirugia-estetica-corporal/lipo-vaser"
  }, "Lipo Váser"), ", una técnica de ", React.createElement(_components.strong, null, "liposucción"), " que, a través de los ultrasonidos, ", React.createElement(_components.strong, null, "consigue hacer líquida la grasa de zonas como el abdomen o las caderas facilitando así su extracción"), ". Gracias a esto se consigue un resultado más preciso, un postoperatorio más rápido y una mayor retracción y adaptación de la piel a los nuevos volúmenes del cuerpo. En otras palabras, menos flacidez de la piel."), "\n", React.createElement(_components.p, null, "Os dejo un video ", React.createElement(_components.a, {
    href: "https://youtu.be/rPaIB-EPaBk"
  }, "aquí"), "."), "\n", React.createElement(_components.p, null, "Está especialmente indicada para personas sin un sobrepeso excesivo que desean moldear su figura y lograr una mayor definición. Tiene además la ventaja de que la grasa retirada del cuerpo se puede insertar en otras zonas donde deseamos un mayor volumen."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
